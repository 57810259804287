:root {
    /*ke*/
    --primaryKE: #245432;
    --primaryBGKE: #e3f3e0;
    --primaryDarkKE: #245432;
    --primaryTextKE: #66CDAA;
    --accentKE: #DFFFD8;
    /**/

    /*Fasax*/
    --primaryFX: #7955A2;
    --primaryBGFX: #F6F0FE;
    --accentFX: #f7d633;
    --primaryTextFX: #6200EE;
    --primaryDarkFX: #543B71;
    /**/

    --primary: #7955A2;
    --primaryBG: #F6F0FE;
    --primaryBgTransparent: rgba(115, 8, 232, 0.1);
    --primaryText: #6200EE;
    --primaryDark: #543B71;

    --secondary: #019592;
    --accent: #f7d633;

    --lightYellow: #FFF3E5;
    --white: #FFFFFF;
    --black: #323232;

    --gray05: #f1f0f0;
    --gray10: #e3e3e3;
    --gray20: #e5e5e5;
    --gray30: #c9c9c9;
    --gray40: #9f9f9f;
    --gray60: #737373;
    --gray80: #565656;

    --background: #FBFBFB;
    --border: #c2c2c2;
    --placeholder: #646464;
    --box-shadow: rgba(34, 60, 80, 0.2);
    --error: #d32f2f;
    --label: #00000099;

}
